import {
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Pagination,
  Switch,
  Table,
  Tag,
} from 'antd';
import {useEffect, useState} from 'react';
import styles from './allRewardSchemes.module.scss';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
  GET_ALL_REWARD_SCHEMES,
  GET_REWARD_SCHEME,
} from 'graphql/queries/rewardScheme';
import DBModal from 'components/modal/modal';
import {UPDATE_REWARD_STATUS} from 'graphql/mutations/rewardScheme';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import dotsIcon from 'images/dots.svg';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const AllRewardSchemes = () => {
  const navigate = useNavigate();
  const [openStatusModal, setStatusModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [rewardScheme, setRewardScheme] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [activeTab, setActiveTab] = useState('ALL');
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams('');

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.RewardScheme),
  );

  const tabs = [
    {label: 'All', value: 'ALL'},
    {label: 'Referral', value: 'REFERRAL'},
    {label: 'Transactional', value: 'TRANSACTIONAL'},
    {label: 'In-Active', value: 'EXPIRED'},
  ];

  const {loading, refetch, data} = useQuery(GET_ALL_REWARD_SCHEMES, {
    variables: {
      ...(['EXPIRED', 'ALL'].includes(activeTab) ? {} : {type: activeTab}),
      ...(activeTab === 'EXPIRED' && {isActive: false}),
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: error => toast.error(error?.message),
  });

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    refetch({page: page, limit: limit});
  };

  useEffect(() => {
    setActiveTab(
      tabs.find(tab => tab.label === searchParams.get('tab'))?.value || 'ALL',
    );
  }, []);

  useEffect(() => {
    setLimit(10);
  }, []);

  const handleLimitChange = size => {
    if (size === limit) return;
    setLimit(size);
    refetch({page: currentPage, limit: size, isActive: isActive});
  };

  const [updateRewardSchemeStatus] = useMutation(UPDATE_REWARD_STATUS);

  const [getSingleRewardScheme, {data: getRewardScheme}] =
    useLazyQuery(GET_REWARD_SCHEME);

  const handleSave = () => {
    updateRewardSchemeStatus({
      variables: {
        isActive,
        rewardScheme,
        expirationDate,
      },
    }).then(({data: {updateRewardSchemeStatus}}) => {
      if (updateRewardSchemeStatus.__typename === 'Error') {
        toast.error(updateRewardSchemeStatus.message);
      } else {
        setStatusModal(false);
        refetch();
        toast.success('Reward Scheme updated successfully');
      }
    });
  };

  const disabledDate = current => {
    // Can not select days before today
    return current && current < moment().startOf('day');
  };

  const handleModal = id => {
    getSingleRewardScheme({
      variables: {
        getRewardSchemeId: id,
        language: 'EN',
      },
    }).then(({data: {getRewardScheme}}) => {
      if (getRewardScheme?.__typename === 'Error') {
        toast.error(getRewardScheme.message);
      } else {
        form.setFieldsValue({
          isActive: getRewardScheme.isActive,
          expiredDate: moment(
            getRewardScheme.expirationDate || '',
            'YYYY-MM-DD HH:mm:ss',
          ),
        });
        setIsActive(getRewardScheme.isActive);
        setExpirationDate(getRewardScheme.expirationDate);
        setStatusModal(true);
      }
    });
  };

  const menu = id => {
    return (
      <Menu>
        <Menu.Item
          disabled={!tabOperations.update}
          onClick={() => {
            handleModal(id);
            setRewardScheme(id);
          }}>
          <div className={styles['menu-items']}>
            <span>Update Status</span>
          </div>
        </Menu.Item>
        <Menu.Item
          disabled={!tabOperations.update}
          onClick={() =>
            navigate(`/dashboard/reward-schemes/update/language-version/${id}`)
          }>
          <div className={styles['menu-items']}>
            <span>Edit</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <div>
          <h4>{record?.type.replaceAll('_', ' ')}</h4>
        </div>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <div>
          <h4>{record?.title}</h4>
        </div>
      ),
    },
    {
      title: 'Waiver',
      dataIndex: 'transactionFeeWaiver',
      key: 'transactionFeeWaiver',
      render: (text, render) => (
        <div className={styles['record-label']}>
          <h4>
            {render?.rewardConfig?.transactionFeeWaiver?.percentage &&
              `${render?.rewardConfig?.transactionFeeWaiver?.percentage}%`}
          </h4>
        </div>
      ),
    },
    {
      title: 'Units',
      dataIndex: 'units',
      key: 'units',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>
            {record?.rewardConfig?.stocks?.rewardUnits}{' '}
            {record?.rewardConfig?.stocks?.ticker}
          </h4>
        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>
            {record?.rewardConfig?.cash?.currency?.currency}{' '}
            {record?.rewardConfig?.cash?.amount}
          </h4>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div className={styles['record-label']}>
          {record?.isActive ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Not Active</Tag>
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      render: (text, record) => (
        <div className={styles.actions}>
          {!record?.isExpired ? (
            <>
              {tabOperations?.update && (
                <Dropdown overlay={menu(record?.id)} placement="bottomLeft">
                  <div className={styles['select-btn']}>
                    <img style={{cursor: 'pointer'}} src={dotsIcon} />
                  </div>
                </Dropdown>
              )}
            </>
          ) : (
            <>
              <Tag color="red">Expired</Tag>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles['table-container']}>
      <div className={styles.header}></div>
      <div className={styles['details-toggler']}>
        {tabs.map(tab => (
          <span
            className={activeTab === tab.value ? styles.active : ''}
            onClick={() => {
              setActiveTab(tab.value);
              setSearchParams({tab: tab.label});
            }}
            key={tab.value}>
            {tab.label}
          </span>
        ))}
      </div>
      <div className={styles['startup-table']}>
        <div>
          <Table
            className={styles['user-table']}
            dataSource={data?.getAllRewardSchemes?.data?.rewardSchemes}
            columns={columns}
            loading={loading}
            pagination={false}
          />
          <Pagination
            current={currentPage}
            onChange={e => handlePageChange(e)}
            total={data?.getAllRewardSchemes?.pagination?.total}
            showQuickJumper
            className="ant-table-pagination"
            showSizeChanger
            pageSize={limit}
            onShowSizeChange={(_, size) => {
              handleLimitChange(size);
            }}
          />
        </div>
      </div>

      <DBModal
        isOpen={openStatusModal}
        handleClose={() => setStatusModal(false)}
        content={
          <div>
            <h1>Change Status</h1>
            <Form
              form={form}
              className={styles.form}
              layout="vertical"
              onFinish={handleSave}>
              <div className={styles['date-format']}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Expiry date required',
                    },
                  ]}
                  name="expiredDate"
                  label="Expiration Date (Time is saved in UTC/GMT)">
                  <DatePicker
                    format={'YYYY-MM-DD HH:mm:ss'}
                    style={{width: 340}}
                    disabledDate={disabledDate}
                    showTime={{
                      defaultValue: moment('00:00', 'HH:mm:ss'),
                    }}
                    defaultValue={moment(
                      getRewardScheme?.getRewardScheme?.expirationDate || '',
                      'YYYY-MM-DD HH:mm:ss',
                    )}
                    value={moment(expirationDate || '', 'YYYY-MM-DD HH:mm:ss')}
                    onChange={e =>
                      setExpirationDate(
                        moment(e).format('YYYY-MM-DDTHH:mm:ss+00:00'),
                      )
                    }
                  />
                </Form.Item>
              </div>
              <Form.Item
                label="Reward Scheme Status"
                name="isActive"
                className={styles.field}>
                <Switch checked={isActive} onChange={e => setIsActive(e)} />
              </Form.Item>
              <SubmitButton label="Update" />
            </Form>
          </div>
        }
      />
    </div>
  );
};

export {AllRewardSchemes};
