import {useEffect, useState} from 'react';
import styles from './newRewardSchemes.module.scss';
import backIcon from 'images/back.png';
import {Form, Input, Modal, Select, Spin, Switch} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  operationTypeEnum,
  rewardAssetClass,
  rewardType,
  userTypeEnum,
  walletCurrencies,
} from 'utils/mock';
import {useMutation, useQuery} from '@apollo/client';
import {
  ISSUE_REWARD,
  UPDATE_ISSUED_REWARD,
} from 'graphql/mutations/rewardScheme';
import {
  GET_ALL_REWARD_SCHEMES,
  GET_ALL_STOCK_REWARDS,
  GET_USER_REWARD_BY_ID,
} from 'graphql/queries/rewardScheme';
import {toast} from 'react-toastify';
import {UserSelectField} from 'components/userSelectField/UserSelectField';

const NewUserRewardScheme = () => {
  const {confirm} = Modal;
  const [type, setType] = useState(null);
  const [typeOfReward, setRewardType] = useState(null);
  const [user, setUser] = useState(null);
  const [referee, setReferee] = useState(null);
  const [referer, setReferer] = useState(null);
  const [operationType, setOperationType] = useState(null);
  const [randomStock, setRandomStock] = useState(false);
  const {id} = useParams();
  const [form] = Form.useForm();

  const {loading, data} = useQuery(GET_ALL_STOCK_REWARDS);
  const {data: getAllRewardSchemes} = useQuery(GET_ALL_REWARD_SCHEMES);
  const {data: rewardData, loading: loadingReward} = useQuery(
    GET_USER_REWARD_BY_ID,
    {
      variables: {
        getUserRewardByIdId: id,
      },
      skip: !id,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      onError: error => toast.error('Something went wrong', error.message),
    },
  );

  const [adminCreateUserReward, {loading: loadingCreate}] =
    useMutation(ISSUE_REWARD);
  const [adminUpdateUserReward, {loading: loadingUpdate}] =
    useMutation(UPDATE_ISSUED_REWARD);

  const {Option} = Select;
  const navigate = useNavigate();

  const fieldWidth = 730;

  useEffect(() => {
    if (id || rewardData) {
      const reward = rewardData?.getUserRewardById;
      setOperationType(reward?.eventType);
      if (reward?.refereeId?.id) {
        const data = reward?.refereeId;
        setReferee(
          `${data?.firstName};${data?.lastName};${data?.id};${data?.email}`,
        );
      }
      if (reward?.referrerId?.id) {
        const data = reward?.referrerId;
        setReferer(
          `${data?.firstName};${data?.lastName};${data?.id};${data?.email}`,
        );
      }
      if (reward?.userId?.id) {
        const data = reward?.userId;
        setUser(
          `${data?.firstName};${data?.lastName};${data?.id};${data?.email}`,
        );
      }
      setType(reward?.userType);
      setRewardType(reward?.userReward?.type);
      form.setFieldsValue({
        eventAmount: reward?.eventAmount,
        assetClass: reward?.eventAssetClass,
        eventId: reward?.eventId,
        type: reward?.userType,
        depositCurrency: reward?.eventCurrency?.currency,
        eventFees: reward?.eventFees,
        operationType: reward?.eventType,
        rewardScheme: reward?.rewardScheme?.id,
        cashCurrency: reward?.userReward?.currency?.currency,
        cashAmount: reward?.userReward?.amount || 0,
        ticker: reward?.userReward?.ticker,
        rewardType: reward?.userReward?.type,
      });
    }
  }, [id, rewardData]);

  const handleSave = formValues => {
    confirm({
      content: 'Are you sure you want to issue out this Reward? Please confirm',
      onOk: () => {
        const values = {
          ...(formValues?.eventAmount && {
            eventAmount: Number(formValues?.eventAmount || 0),
          }),
          eventAssetClass: formValues?.assetClass,
          eventCurrency: formValues?.depositCurrency,
          ...(formValues?.eventFee && {
            eventFees: Number(formValues?.eventFee || 0),
          }),
          eventId: formValues?.eventId,
          eventType: operationType,
          refereeId: referee?.split(';')[2],
          referrerId: referer?.split(';')[2],
          rewardScheme: formValues?.rewardScheme,
          userId: user?.split(';')[2],
          userReward: {
            currency: formValues?.cashCurrency,
            ...(formValues?.cashAmount && {
              amount: Number(formValues?.cashAmount || 0),
            }),
            ...(formValues.units && {
              rewardUnits: Number(formValues?.units || 0),
            }),
            ticker: formValues?.ticker,
            type: typeOfReward,
          },
          userType: type,
        };
        if (id) {
          adminUpdateUserReward({
            variables: {
              adminUpdateUserRewardId: id,
              input: {
                ...values,
              },
            },
          }).then(({data: {adminUpdateUserReward}}) => {
            if (adminUpdateUserReward.__typename === 'Error') {
              toast.error(adminUpdateUserReward.message);
            } else {
              toast.success('Reward issued Updated successfully');
              navigate(`/dashboard/reward-schemes/users?tab=Pending`);
            }
          });
        } else
          adminCreateUserReward({
            variables: {
              input: {
                ...values,
              },
            },
          }).then(({data: {adminCreateUserReward}}) => {
            if (adminCreateUserReward.__typename === 'Error') {
              toast.error(adminCreateUserReward.message);
            } else {
              toast.success('Reward issued out successfully');
              navigate('/dashboard/reward-schemes/users?tab=Pending');
            }
          });
      },
    });
  };

  if (id && loadingReward) {
    return (
      <div className="query-loading">
        <Spin />
        <h1>Loading Reward</h1>
      </div>
    );
  }

  return (
    <div className={styles.rewardRoot}>
      <div className={styles.navigation}>
        <img src={backIcon} alt="Go back" onClick={() => navigate(-1)} />
        <span>
          <span className={styles['h-style']}>
            {id ? 'Update' : 'New'} User Reward
          </span>
        </span>
      </div>
      <div className={styles.form}>
        <Form form={form} onFinish={handleSave} layout="vertical">
          <Form.Item
            label="User Type"
            className={styles.field}
            name="type"
            rules={[
              {
                required: true,
                message: 'User type is required',
              },
            ]}>
            <Select
              style={{width: fieldWidth}}
              value={type}
              disabled={id}
              allowClear
              onChange={e => setType(e)}>
              {userTypeEnum.map(({key, name}) => (
                <Option key={key} value={key}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {type === 'USER' && (
            <div style={{width: fieldWidth}}>
              <Form.Item
                name="userId"
                label="Select User"
                rules={[
                  {
                    required: !user?.length,
                    message: 'User is required',
                  },
                ]}>
                <UserSelectField
                  name="userId"
                  users={user}
                  setUsers={setUser}
                  defaultUsers={
                    !loadingReward && rewardData?.getUserRewardById?.userId?.id
                      ? [rewardData?.getUserRewardById?.userId?.id]
                      : null
                  }
                  mode="single"
                />
              </Form.Item>
            </div>
          )}
          {type === 'REFERRER' && (
            <>
              <div style={{width: fieldWidth}}>
                <Form.Item
                  name="referer"
                  label="Select Referer"
                  rules={[
                    {
                      required: !referer?.length,
                      message: 'Referer is required',
                    },
                  ]}>
                  <UserSelectField
                    name="referee"
                    users={referer}
                    defaultUsers={
                      !loadingReward &&
                      rewardData?.getUserRewardById?.referrerId?.id
                        ? [rewardData?.getUserRewardById?.referrerId?.id]
                        : null
                    }
                    setUsers={setReferer}
                    mode="single"
                  />
                </Form.Item>
              </div>
              <div style={{width: fieldWidth}}>
                <Form.Item name="referee" label="Select Referee">
                  <UserSelectField
                    users={referee}
                    defaultUsers={
                      !loadingReward &&
                      rewardData?.getUserRewardById?.refereeId?.id
                        ? [rewardData?.getUserRewardById?.refereeId?.id]
                        : null
                    }
                    setUsers={setReferee}
                    mode="single"
                  />
                </Form.Item>
              </div>
            </>
          )}
          <Form.Item label="Transaction Type" name="operationType">
            <Select
              style={{width: 340}}
              value={operationType}
              onChange={e => setOperationType(e)}
              name="operationType">
              {operationTypeEnum.map(({key, name}) => (
                <Option key={key} value={key}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Deposited/Invested Amount"
            className={styles.field}
            name="eventAmount">
            <Input
              type="number"
              style={{width: fieldWidth}}
              name="eventAmount"
              placeholder="0.00"
            />
          </Form.Item>
          <Form.Item
            label="Transaction Fees"
            className={styles.field}
            name="eventFee">
            <Input
              type="number"
              style={{width: fieldWidth}}
              name="eventFee"
              placeholder="0.00"
            />
          </Form.Item>
          {operationType === 'DEPOSIT' && (
            <>
              <div>
                <Form.Item
                  label="Currency"
                  name="depositCurrency"
                  style={{width: fieldWidth}}
                  rules={[
                    {
                      required: true,
                      message: 'Deposit currency is required',
                    },
                  ]}>
                  <Select
                    allowClear
                    name="depositCurrency"
                    placeholder="Currency">
                    {walletCurrencies.map(({key}) => (
                      <Option key={key} value={key}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </>
          )}
          <div className={styles['date-format']}>
            <Form.Item
              label="Reward Scheme"
              className={styles.field}
              name="rewardScheme">
              <Select allowClear style={{width: 340}} name="rewardScheme">
                {getAllRewardSchemes?.getAllRewardSchemes?.data?.rewardSchemes?.map(
                  (item, index) => (
                    <Option key={index} value={item.id}>
                      {item?.title} - {item?.type.replaceAll('_', ' ')}
                    </Option>
                  ),
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Reward Type"
              className={styles.field}
              name="rewardType"
              rules={[
                {
                  required: true,
                  message: 'Reward type is required',
                },
              ]}>
              <Select
                style={{width: 340}}
                allowClear
                value={typeOfReward}
                onChange={e => setRewardType(e)}
                name="rewardType">
                {rewardType?.map(({key, name}) => (
                  <Option key={key} value={key}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {typeOfReward === 'TRANSACTIONAL' ||
            (typeOfReward === 'CASH_TO_INVEST' && (
              <div className={styles['date-format']}>
                <Form.Item
                  label="Amount"
                  className={styles.field}
                  name="cashAmount"
                  rules={[
                    {
                      required: true,
                      message: 'amount is required',
                    },
                  ]}>
                  <Input
                    type="number"
                    style={{width: 340}}
                    name="cashAmount"
                    placeholder="0.00"
                  />
                </Form.Item>
                <Form.Item
                  label="Currency"
                  className={styles.field}
                  name="cashCurrency"
                  rules={[
                    {
                      required: true,
                      message: 'currency is required',
                    },
                  ]}>
                  <Select style={{width: 340}} name="cashCurrency">
                    {walletCurrencies.map(({key}) => (
                      <Option value={key} key={key}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            ))}
          {typeOfReward === 'STOCKS' && (
            <>
              <Form.Item label="Pick Stock Randomly" className={styles.field}>
                <Switch
                  defaultChecked={randomStock}
                  onChange={e => setRandomStock(e)}
                />
              </Form.Item>
              <div className={styles['date-format']}>
                {!randomStock && (
                  <Form.Item
                    label="Ticker"
                    className={styles.field}
                    name="ticker">
                    <Select
                      style={{width: 340}}
                      name="ticker"
                      allowClear
                      loading={loading}>
                      {data.getAllStockBanks?.data?.stockRewardBank.map(
                        (item, index) => (
                          <Option value={item.ticker} key={index}>
                            {item.ticker}
                          </Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  label="Units"
                  rules={[
                    {
                      required: true,
                      message: 'Units is required',
                    },
                  ]}
                  className={styles.field}
                  name="units">
                  <Input style={{width: 340}} name="units" />
                </Form.Item>
              </div>
            </>
          )}
          {operationType === 'INVESTMENT' && (
            <Form.Item
              label="Asset Class"
              className={styles.field}
              name="assetClass"
              rules={[
                {
                  required: true,
                  message: 'Asset class is required',
                },
              ]}>
              <Select
                allowClear
                mode="tag"
                style={{width: fieldWidth}}
                name="assetClass">
                {rewardAssetClass.map(({key, name}) => (
                  <Option value={key} key={key}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <div className={styles['date-format']}>
            <Form.Item label="Transaction ID (If any)" name="eventId">
              <Input
                type="number"
                style={{width: 340}}
                name="eventId"
                placeholder="xxxx"
              />
            </Form.Item>
          </div>
          <SubmitButton
            disabled={loadingCreate || loadingUpdate}
            label={id ? 'Update Reward' : 'Issue Reward'}
          />
        </Form>
      </div>
    </div>
  );
};

export {NewUserRewardScheme};
