import {gql} from '@apollo/client';

export const GET_ALL_REWARD_SCHEMES = gql`
  query GetAllRewardSchemes(
    $page: Int
    $limit: Int
    $type: RewardSchemeType
    $isActive: Boolean
  ) {
    getAllRewardSchemes(
      page: $page
      limit: $limit
      type: $type
      isActive: $isActive
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAllRewardSchemes {
        pagination {
          page
          limit
          pages
          total
          hasPrevPage
          hasNextPage
          previous
          next
        }
        message
        data {
          stats {
            totalDepositGeneratedFromRewards {
              totalAmount
              currency
            }
            totalActiveRewardPrograms
            totalNewUsersFromRewards
          }
          rewardSchemes {
            id
            title
            type
            rewardConfig {
              stocks {
                ticker
                rewardUnits
              }
              cash {
                amount
                currency {
                  currencySymbol
                  currency
                  currencyName
                }
              }
              transactionFeeWaiver {
                percentage
              }
            }
            isActive
            isExpired
          }
        }
      }
    }
  }
`;

export const GET_REWARD_SCHEME = gql`
  query GetRewardScheme($getRewardSchemeId: ID!, $language: Languages!) {
    getRewardScheme(id: $getRewardSchemeId, language: $language) {
      ... on Error {
        message
        statusCode
      }
      ... on TypeRewardScheme {
        id
        title
        description
        type
        rewardType {
          referral {
            referee
            referrer
          }
          transactional
        }
        maxNumberOfRewards
        operationConfig {
          type
          rewardTriggerOccurrence
          assetClass
          cumulativeDurationUnit
          cumulativeDurationValue
          instructions {
            titleText
            descriptionText
            icon
          }
          allowedCurrencies {
            currency {
              currencySymbol
              currency
              currencyName
            }
            minTransactionValue
          }
        }
        isActive
        isExpired
        expirationDate
        startDate
        allowConcurrency
        rewardConfig {
          stocks {
            ticker
            rewardUnits
          }
          cash {
            currency {
              currencySymbol
              currency
              currencyName
            }
            amount
          }
          transactionFeeWaiver {
            maxFeeWaiverConfig {
              currency {
                currency
                currencySymbol
                currencyName
              }
              value
            }
            percentage
          }
        }
        createdAt
        updatedAt
        language
      }
    }
  }
`;

export const GET_USER_REWARDS = gql`
  query AdminGetAllUserRewards(
    $page: Int
    $limit: Int
    $user: ID
    $status: RewardStatus
    $rewardSchemeId: ID
  ) {
    adminGetAllUserRewards(
      page: $page
      limit: $limit
      user: $user
      status: $status
      rewardSchemeId: $rewardSchemeId
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAllUserRewards {
        data {
          id
          status
          createdAt
          entryMode
          eventAmount
          eventCurrency {
            currency
            currencySymbol
            currencyName
          }
          eventType
          userId {
            firstName
            id
            lastName
          }
          refereeId {
            id
            firstName
            lastName
          }
          referrerId {
            firstName
            id
            lastName
          }
          userType
          userReward {
            type
            ticker
            stockPrice
            rewardUnits
            cashValue
            amount
            currency {
              currency
            }
          }
        }
        message
        pagination {
          page
          limit
          pages
          total
          hasPrevPage
          hasNextPage
          previous
          next
        }
      }
    }
  }
`;

export const GET_ALL_STOCK_REWARDS = gql`
  query GetAllStockBanks($page: Int, $limit: Int) {
    getAllStockBanks(page: $page, limit: $limit) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAllStockRewardBank {
        message
        data {
          stockRewardBank {
            id
            purchasedUnits
            ticker
            disbursedUnits
          }
          stockStats {
            totalStocksUnitsLeft
            totalStocksUnitIssued
            totalNewUsersFromStockReward
          }
        }
        pagination {
          page
          limit
          pages
          total
          hasPrevPage
          hasNextPage
          previous
          next
        }
      }
    }
  }
`;

export const GET_ALL_CASH_BANKS = gql`
  query GetAllCashBanks($page: Int, $limit: Int) {
    getAllCashBanks(page: $page, limit: $limit) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAllCashRewardBank {
        message
        data {
          cashRewardBank {
            id
            amountDisbursed
            amountDeposited
            currency
          }
          cashStats {
            totalNewUsersFromRewards
          }
        }
        pagination {
          page
          limit
          pages
          total
          hasPrevPage
          hasNextPage
          previous
          next
        }
      }
    }
  }
`;

export const GET_ALL_STOCKS_BY_STOCK_EXCHANGE = gql`
  query GetAllStocksByStockExchange($stockExchangeCode: String!) {
    getAllStocksByStockExchange(stockExchangeCode: $stockExchangeCode) {
      ... on Error {
        message
        statusCode
      }
      ... on CompanyStockType {
        stocks {
          company {
            name
            logoImgURL
            StockExchange {
              id
            }
            id
          }
          symbol
        }
      }
    }
  }
`;

export const GET_REWARD_LANGUAGE_VERSION = gql`
  query GetRewardsLanguageVersions($getRewardsLanguageVersionsId: ID!) {
    getRewardsLanguageVersions(id: $getRewardsLanguageVersionsId) {
      ... on Error {
        message
        statusCode
      }
      ... on RewardsLanguageVersion {
        languages
        title
        rewardScheme
      }
    }
  }
`;

export const GET_USER_REWARD_BY_ID = gql`
  query GetUserRewardById($getUserRewardByIdId: ID!) {
    getUserRewardById(id: $getUserRewardByIdId) {
      ... on Error {
        statusCode
        message
      }
      ... on UserReward {
        referrerId {
          email
          id
          firstName
          lastName
        }
        refereeId {
          email
          id
          firstName
          lastName
        }
        userId {
          email
          id
          firstName
          lastName
        }
        rewardScheme {
          id
        }
        userReward {
          currency {
            currency
            currencyName
          }
          type
          ticker
          rewardUnits
          amount
        }
        eventType
        eventId
        eventAmount
        eventFees
        eventAssetClass
        eventCurrency {
          currency
          currencyName
        }
        status
        userType
        id
        createdAt
        entryMode
      }
    }
  }
`;
