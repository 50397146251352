import Thumbnail1 from '../images/thumb1.png';
import Thumbnail2 from '../images/thumb2.png';
import Thumbnail3 from '../images/thumb3.png';
import PostImg from '../images/post2.png';
import NewsLarge from '../images/news.png';
import Avatar from '../images/avatar.png';
import InvestmentRisk from 'images/InvestmentRisk.png';
import CompetitiveAdvantage from 'images/CompetitiveAdvantage.png';
import CityCollapse from 'images/CityCollapse.png';
import Thesis from 'images/Thesis.png';

import cry from '../images/cry.png';
import clap from '../images/clap.png';
import fire from '../images/fire.png';
import Share from '../images/share.png';
import Download from '../images/download.svg';
import Pitch from '../images/pitch.png';
import PayStack from '../images/paystack.png';
import Wiflix from '../images/wiflix.png';
import CompanyDocs from '../images/CompanyDocs.png';
import Spot from '../images/spot.svg';
import FB from '../images/facebook.png';
import TW from '../images/twitter.png';
import LN from '../images/linkedin.png';
import STAFF5 from '../images/staff5.png';
import STAFF4 from '../images/staff4.png';
import STAFF3 from '../images/staff3.png';
import STAFF2 from '../images/staff2.png';
import STAFF1 from '../images/staff1.png';
import moment from 'moment';
import AgricImage from 'images/agric_clear.png';
import BlockchainImage from 'images/blockchain_clear.png';
import AiImage from 'images/ai_clear.png';
import john from 'images/john.png';
import howard from 'images/howard.png';
import richie from 'images/richie.png';
import DabaSupport from 'images/daba-support.png';
import Friend1 from 'images/friend.png';
import Friend2 from 'images/friend2.png';
import Naija from 'images/naija.png';
import FinTechBase from 'images/fintech-base.png';
import dangote from 'images/dangote.png';
import mtn from 'images/mtn.png';
import jumia from 'images/jumia.png';
import konga from 'images/konga.png';
import nigeria from 'images/interests/nigeria.png';
import kenya from 'images/interests/kenya.png';
import gabon from 'images/Gabon_round.png';
import cameroon from 'images/Cameroon_round.png';

export const emojis = ['🎉', '👍', '👏', '❤️', '🤔'];
export const tags = ['Finance', 'Technology'];
export const companies = ['Paystack', 'Flutterwave', 'Daba', 'Piggyvest'];

export const stageData = [
  {value: 'PRE_SEED', label: 'Pre Seed'},
  {value: 'SEED', label: 'Seed'},
  {value: 'PRE_SERIES_A', label: 'Pre Series A'},
  {value: 'SERIES_A', label: 'Series A'},
  {value: 'PRE_SERIES_B', label: 'Pre Series B'},
  {value: 'SERIES_B', label: 'Series B'},
  {value: 'SERIES_C', label: 'Series_C'},
  {value: 'SERIES_D', label: 'Series D'},
  {value: 'SERIES_E', label: 'Series E'},
  {value: 'ACQUIRED', label: 'Acquired'},
  {value: 'IPO', label: 'Ipo'},
  {value: 'Closed', label: 'Closed'},
];

export const numberOfEmployeesEnum = [
  {
    value: 'BETWEEN_ZERO_AND_ONE',
    label: '0 - 1',
  },
  {
    value: 'BETWEEN_TWO_AND_TEN',
    label: '2 - 10',
  },
  {
    value: 'BETWEEN_ELEVEN_AND_FIFTY',
    label: '11 - 50',
  },
  {
    value: 'BETWEEN_FIFTY_ONE_AND_TWO_HUNDRED',
    label: '51 - 200',
  },
  {
    value: 'BETWEEN_TWO_HUNDRED_AND_FIVE_HUNDRED',
    label: '200 - 500',
  },
  {
    value: 'BETWEEN_FIVE_HUNDRED_AND_ONE_AND_ONE_THOUSAND',
    label: '500 - 1000',
  },
  {
    value: 'BETWEEN_ONE_THOUSAND_AND_FIVE_THOUSAND',
    label: '1000 - 5000',
  },
  {
    value: 'BETWEEN_FIVE_THOUSAND_AND_TEN_THOUSAND',
    label: '5000 - 10,000',
  },
  {
    value: 'MORE_THAN_TEN_THOUSAND',
    label: 'Above 10,000',
  },
];

export const formatTypeEnum = [
  {
    value: 'CURRENCYFIELD',
    label: 'Currency Field',
  },
  {
    value: 'NUMBER',
    label: 'Number',
  },
  {
    value: 'PERCENTAGE',
    label: 'Percentage',
  },
  {
    value: 'RATIO',
    label: 'Ratio',
  },
  {
    value: 'TEXT',
    label: 'Text',
  },
];

export const disclosureTypeEnum = [
  {
    value: 'SHORT_DISCLOSURE',
    label: 'Short Disclosure',
  },
  {
    value: 'LONG_DISCLOSURE',
    label: 'Long Disclosure',
  },
];

export const thumbnails = [john, howard, richie];

export const countriesInterest = [
  {
    logo: nigeria,
    label: 'Nigeria',
  },
  {
    logo: kenya,
    label: 'Kenya',
  },
  {
    logo: gabon,
    label: 'Gabon',
  },
  {
    logo: cameroon,
    label: 'Cameroon',
  },
];

export const interestData = [
  {
    logo: konga,
    label: 'Konga',
  },
  {
    logo: konga,
    label: 'Konga',
  },

  {
    logo: jumia,
    label: 'Jumia',
  },
  {
    logo: mtn,
    label: 'MTN',
  },
  {
    logo: dangote,
    label: 'Dangote',
  },
  {
    logo: AgricImage,
    label: 'Agriculture',
  },
  {
    logo: AgricImage,
    label: 'Agriculture',
  },
  {
    logo: AiImage,
    label: 'Artificial Intelligence',
  },
  {
    logo: AgricImage,
    label: 'Agriculture',
  },
  {
    logo: BlockchainImage,
    label: 'Blockchain',
  },
  {
    logo: AgricImage,
    label: 'Agriculture',
  },
];

export const userData = {
  name: 'Aise Idahor',
  handle: 'aiseidahor',
  completion: 100,
  tracker: {
    hasTracker: false,
    percentageChange: 12,
    currency: '$',
    balance: 100000,
    investmentsCount: 4,
    investmentBalance: 70000,
  },
};

export const usersSavedTableData = [
  {
    key: '1',
    image: PayStack,
    name: 'Paystack',
    investors: 300,
    amount: 450000000,
    funding: 'Series A',
  },
  {
    key: '1',
    image: PayStack,
    name: 'Paystack',
    investors: 300,
    amount: 450000000,
    funding: 'Series A',
  },
  {
    key: '1',
    image: Wiflix,
    name: 'Wiflix',
    investors: 300,
    amount: 450000000,
    funding: 'Series A',
  },
  {
    key: '1',
    image: dangote,
    name: 'Dangote',
    investors: 300,
    amount: 450000000,
    funding: 'Series A',
  },
  {
    key: '1',
    image: mtn,
    name: 'MTN',
    investors: 300,
    amount: 450000000,
    funding: 'Series A',
  },
  {
    key: '1',
    image: jumia,
    name: 'Jumia',
    investors: 300,
    amount: 450000000,
    funding: 'Series B',
  },
];

export const planUserTable = [
  {
    key: '1',
    image: STAFF1,
    expiration: '21 days',
    name: 'Kristan Watson',
    username: 'Krist123',
    status: 'active',
    date: '03rd Mar, 2022',
  },
  {
    key: '2',
    image: STAFF2,
    expiration: '21 days',
    name: 'Kristan Watson',
    username: 'Krist123',
    status: 'pending',
    date: '03rd Mar, 2022',
  },
  {
    key: '3',
    image: STAFF3,
    expiration: '21 days',
    name: 'Steeve Watson',
    username: 'Krist123',
    status: 'active',
    date: '03rd Mar, 2022',
  },
  {
    key: '5',
    image: STAFF4,
    expiration: '21 days',
    name: 'Kristan Jobs',
    username: 'Krist123',
    status: 'pending',
    date: '03rd Mar, 2022',
  },
];

export const userChatItems = [
  {
    name: 'Support',
    avatar: DabaSupport,
    duration: '4d',
    text: 'Hello Kofo, I’m unable to create a tour for...',
    unread: '2',
  },
  {
    name: 'Esther Howard',
    avatar: Friend1,
    duration: '2h',
    text: 'Hello Kofo, I’m unable to create a tour for...',
  },
  {
    name: 'Arlene McCoy',
    avatar: Friend2,
    duration: 'yesterday',
    text: 'Hello Kofo, I’m unable to create a tour for...',
  },
  {
    name: 'Theresa Webb',
    avatar: Friend1,
    duration: 'yesterday',
    text: 'Hello Kofo, I’m unable to create a tour for...',
  },
  {
    name: 'Bessie Cooper',
    avatar: Friend2,
    duration: 'yesterday',
    text: 'Hello Kofo, I’m unable to create a tour for...',
  },
];

export const userChatMessages = [
  {
    text: 'Hello Kofo, I’m unable to create payroll to pay the salaries of all my employees. Kindly assist.',
    avatar: Friend1,
    duration: '10:22',
    sender: false,
  },
  {
    text: 'Hi Wolede! We are looking at the issue now, we’d respond to you shortly. ^BO',
    avatar: Friend2,
    duration: '10:24',
    sender: true,
  },
  {
    text: 'Thank you, Kofo. I’d be expecting your response.',
    avatar: Friend1,
    duration: '10:25',
    sender: false,
  },
  {
    text: 'Hi Wolede, the issue has now been now been fixed. Kindly confirm that you can run your payroll. ^BO',
    avatar: Friend2,
    duration: '10:30',
    sender: true,
  },
  {
    text: 'The issue has been resolved. Thank you Busola.',
    avatar: Friend1,
    duration: '10:32',
    sender: false,
  },
];

export const groupChatItems = [
  {
    name: 'Nigeria',
    avatar: Naija,
    duration: '2h',
    text: 'Hello Kofo, I’m unable to create a tour for...',
  },
  {
    name: 'Fintech Base',
    avatar: FinTechBase,
    duration: 'yesterday',
    text: 'Hello Kofo, I’m unable to create a tour for...',
  },
  {
    name: 'Pitching',
    avatar: Friend2,
    duration: 'yesterday',
    text: 'Hello Kofo, I’m unable to create a tour for...',
  },
];

export const groupChatMessages = [
  {
    text: 'Hello Kofo, I’m unable to create payroll to pay the salaries of all my employees. Kindly assist.',
    avatar: Friend1,
    duration: '2m',
    sender: false,
    messageSender: 'Peter Pierce',
  },
  {
    text: 'Hi Wolede! We are looking at the issue now, we’d respond to you shortly. ^BO',
    avatar: Friend1,
    duration: '10:24',
    sender: true,
  },
  {
    text: 'Thank you, Kofo. I’d be expecting your response.',
    avatar: Friend1,
    duration: '2m',
    sender: false,
    messageSender: 'Peter Pierce',
  },
  {
    text: 'Hi Wolede, the issue has now been now been fixed. Kindly confirm that you can run your payroll. ^BO',
    avatar: Friend2,
    duration: '10:30',
    sender: true,
  },
  {
    text: 'The issue has been resolved. Thank you Busola.',
    avatar: Friend1,
    duration: '2m',
    sender: false,
    messageSender: 'Peter Pierce',
  },
];

export const filterData = [
  {
    type: 'Sort By',
    schema: [
      {
        name: 'Agriculture',
        value: 'agriculture',
        isChecked: true,
      },
      {
        name: 'Edu Tech',
        value: 'eduTech',
        isChecked: true,
      },
    ],
  },
  {
    type: 'All Categories',
    schema: [
      {
        name: 'Agriculture',
        value: 'agriculture',
        isChecked: false,
      },
      {
        name: 'Edu Tech',
        value: 'eduTech',
        isChecked: true,
      },
    ],
  },
  {
    type: 'Highlights',
    schema: [
      {
        name: 'CNN',
        value: 'cnn',
        isChecked: false,
      },
      {
        name: 'NNC',
        value: 'nnc',
        isChecked: false,
      },
    ],
  },
  {
    type: 'Revenue',
    schema: [
      {
        name: 'CNN',
        value: 'cnn',
        isChecked: false,
      },
    ],
  },
  {
    type: 'Impact',
    schema: [
      {
        name: 'CNN',
        value: 'cnn',
        isChecked: false,
      },
    ],
  },
  {
    type: 'Valuation',
    schema: [
      {
        name: 'CNN',
        value: 'cnn',
        isChecked: false,
      },
    ],
  },
  {
    type: 'Min. Investment',
    schema: [
      {
        name: 'CNN',
        value: 'cnn',
        isChecked: false,
      },
    ],
  },
];

const pricePerformanceData = [
  {
    amount: 2.156,
    percent: 12,
    label: 'Today',
  },
  {
    amount: 0.156,
    percent: -0.34,
    label: 'After - Hour',
  },
];

export const increasingStockDataPoints = [
  15.07, 14.89, 14.45, 14.3, 14.94, 13.93, 14.33, 14.17, 13.62, 13.57, 13.6,
  13.14, 13.28, 13.49, 13.37, 13.5, 14.01, 14.52, 14.7, 14.99, 14.85, 15.14,
  15.54, 15.98, 16.45, 15.8, 16.48, 17.13, 17.21, 17.1, 16.92, 16.67, 16.48, 17,
  17.02, 16.48, 15.82, 15.12, 15.17, 15.82, 16.07, 15.74, 15.77, 16.11, 16.2,
  15.96, 16.17, 16.54, 16.1, 15.99, 15.33, 16.26, 16.55, 16.38, 16.7, 16.8,
  16.91, 16.72, 17.02, 17.05, 17.15, 17.01, 17.53, 17.61, 17.74, 17.82, 18.37,
  18.96, 19.38, 19.05, 18.55, 18.06, 17.48, 18.11, 18.44, 18.06, 17.92, 17.66,
  17.54, 17.78, 17.15, 16.92, 16.89, 17.09, 16.94, 16.97, 16.85, 16.96, 17.02,
  16.94, 17.16, 16.73, 16.8, 16.06, 16.22, 16.5, 16.29, 16.24, 16.5, 16.78,
  16.06, 16.93, 16.94, 17.01, 17.32, 17.4, 17.36, 17.66, 17.86, 17.96, 18.7,
  18.26, 18.25, 18.33, 18.43, 18.49, 18.74, 19.07, 19.33, 19.25, 19.44, 19.38,
  18.9, 19.17, 18.84, 18.99, 18.91, 18.49,
];

export const opportunityDetailData = [
  ...increasingStockDataPoints.map((inst, index) => {
    return {
      amount: inst,
      date: moment().subtract(index, 'days').format('L'),
    };
  }),
];

export const marketPerformanceData = [
  ...increasingStockDataPoints.map((inst, index) => {
    return {
      amount: inst,
      date: moment().subtract(index, 'days').format(),
    };
  }),
];

const marketTableData = {
  headers: ['Index', 'Year to date', 'Today', 'Market cap'],
  body: [
    ['2,762.03', '-212.45', '-3.45%', '+$5.6b'],
    ['2,762.03', '+212.45', '-3.45%', '$5.6b'],
  ],
};

const barChartMockData = {
  labels: ['Q4 2020', 'Q3 2020', 'Q2 2020', 'Q1 2020'],
  datasets: [
    {
      label: 'Expected EPS: $12.34',
      backgroundColor: '#D8CDFC',
      borderColor: '#D8CDFC',
      borderWidth: 0,
      data: [3, 5, 6, 7],
      maxBarThickness: 10,
      categoryPercentage: 0.2,
      barPercentage: 0.3,
    },
    {
      label: 'Actual EPS: $10.34',
      backgroundColor: '#8D6CFA',
      borderColor: '#8D6CFA',
      borderWidth: 0,
      data: [4, 7, 3, 6],
      maxBarThickness: 10,
      categoryPercentage: 0.2,
      barPercentage: 0.3,
    },
  ],
};

const investorRemarkItem = {
  likeHandler: () => {},
  shareHandler: () => {},
  name: 'Leslie Alexander',
  invested: 'Invested: $50,000',
  description:
    'Lorem ther hah jagaban laiala ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.',
  counts: 122,
  image: Avatar,
  thumbnails: [Thumbnail1, Thumbnail2, Thumbnail3],
  topReactions: [{img: cry}, {img: clap}, {img: fire}],
  emojiOptions: [...emojis],
  // emojiIcon: <HeartOutlined />,
  likeText: 'Like',
  shareImg: Share,
  className: '',
};

export const rewardSchemesMockup = {
  'page': 1,
  'totalDocs': 6,
  'hasPrevPage': false,
  'hasNextPage': false,
  'prevPage': null,
  'nextPage': null,
  'offset': 0,
  'docs': [
    {
      'id': '65fc1b72d1aa069567da306e',
      'title': 'Two rewards scheme for cash to invest',
      'type': 'TRANSACTIONAL',
      'language': 'EN',
      'isActive': false,
      'maxNumberOfRewards': 3,
      'operationConfig': {
        'rewardTriggerOccurrence': 'FIRST',
      },
      'rewardConfig': {
        'stocks': {
          'ticker': null,
          'rewardUnits': null,
        },
        'cash': {
          'currency': 'USD',
          'amount': 10,
        },
        'transactionFeeWaiver': {
          'percentage': null,
        },
      },
    },
    {
      'id': '65fadabd4be165c327201732',
      'title': 'Two rewards scheme for transaction fee waiver',
      'type': 'TRANSACTIONAL',
      'language': 'EN',
      'isActive': false,
      'maxNumberOfRewards': 3,
      'operationConfig': {
        'rewardTriggerOccurrence': 'FIRST',
      },
      'rewardConfig': {
        'stocks': {
          'ticker': null,
          'rewardUnits': null,
        },
        'cash': {
          'currency': null,
          'amount': null,
        },
        'transactionFeeWaiver': {
          'percentage': 100,
        },
      },
    },
    {
      'id': '65fada5d4be165c3272016cc',
      'title': 'Two rewards scheme for stocks',
      'type': 'TRANSACTIONAL',
      'language': 'EN',
      'isActive': true,
      'maxNumberOfRewards': 3,
      'operationConfig': {
        'rewardTriggerOccurrence': 'ANY',
      },
      'rewardConfig': {
        'stocks': {
          'ticker': null,
          'rewardUnits': 5,
        },
        'cash': {
          'currency': null,
          'amount': null,
        },
        'transactionFeeWaiver': {
          'percentage': null,
        },
      },
    },
    {
      'id': '65fad9a14be165c327201616',
      'title': 'Two rewards scheme for cash',
      'type': 'TRANSACTIONAL',
      'language': 'EN',
      'isActive': false,
      'maxNumberOfRewards': 3,
      'operationConfig': {
        'rewardTriggerOccurrence': 'ANY',
      },
      'rewardConfig': {
        'stocks': {
          'ticker': null,
          'rewardUnits': null,
        },
        'cash': {
          'currency': 'XOF',
          'amount': 1000,
        },
        'transactionFeeWaiver': {
          'percentage': null,
        },
      },
    },
    {
      'id': '65f4655802fa3a0d200cc156',
      'title': 'Two rewards referral scheme',
      'type': 'REFERRAL',
      'language': 'EN',
      'isActive': false,
      'maxNumberOfRewards': 3,
      'operationConfig': {
        'rewardTriggerOccurrence': 'ANY',
      },
      'rewardConfig': {
        'stocks': {
          'ticker': null,
          'rewardUnits': null,
        },
        'cash': {
          'currency': 'XOF',
          'amount': 900,
        },
        'transactionFeeWaiver': {
          'percentage': 50,
        },
      },
    },
    {
      'id': '65ea0320b9c0fcb3f6a5e7a3',
      'title': 'CFAC stock Referral Scheme',
      'type': 'REFERRAL',
      'language': 'EN',
      'isActive': false,
      'maxNumberOfRewards': 4,
      'operationConfig': {
        'rewardTriggerOccurrence': 'ANY',
      },
      'rewardConfig': {
        'stocks': {
          'ticker': 'CFAC',
          'rewardUnits': 2,
        },
        'cash': {
          'currency': null,
          'amount': null,
        },
        'transactionFeeWaiver': {
          'percentage': null,
        },
      },
    },
  ],
};

export const stockBankMock = {
  'page': 1,
  'totalDocs': 3,
  'hasPrevPage': false,
  'hasNextPage': false,
  'prevPage': null,
  'nextPage': null,
  'offset': 0,
  'docs': [
    {
      'id': '65eea5eaadaa63f2754c7f46',
      'ticker': 'SIBC',
      'purchasedUnits': 200,
      'disbursedUnits': 5,
      'stockExchangeId': '64933765d3d8bd2b33d24b97',
      'lastPrice': 80,
      'currentPrice': null,
      'priceHistory': {
        'price': null,
        'date': null,
      },
      'currency': 'XOF',
    },
    {
      'id': '65f2be234d1062e764cbbae4',
      'ticker': 'ETIT',
      'purchasedUnits': 200,
      'disbursedUnits': 0,
      'stockExchangeId': null,
      'lastPrice': 17,
      'currentPrice': null,
      'priceHistory': {
        'price': null,
        'date': null,
      },
      'currency': 'XOF',
    },
    {
      'id': '65e9d0eebb0bcf87e7b646d8',
      'ticker': 'CFAC',
      'purchasedUnits': 200,
      'disbursedUnits': 12,
      'stockExchangeId': '64933765d3d8bd2b33d24b97',
      'lastPrice': 1000,
      'currentPrice': null,
      'priceHistory': {
        'price': null,
        'date': null,
      },
      'currency': 'XOF',
    },
  ],
};

export const cashBankMock = {
  'page': 1,
  'totalDocs': 3,
  'hasPrevPage': false,
  'hasNextPage': false,
  'prevPage': null,
  'nextPage': null,
  'offset': 0,
  'docs': [
    {
      'id': '65fc1e343ab69645966cc7cb',
      'amountDisbursed': 0,
      'amountDeposited': 1000,
      'currency': 'USD',
    },
    {
      'id': '65eea608adaa63f2754c7f67',
      'amountDisbursed': 0,
      'amountDeposited': 1000,
      'currency': 'EUR',
    },
    {
      'id': '65eea454adaa63f2754c7dd2',
      'amountDisbursed': 3600,
      'amountDeposited': 3000,
      'currency': 'XOF',
    },
  ],
};

const docs = {
  documentURL: '',
  thumbnailImage: CompanyDocs,
  title: 'CAC Document.pdf',
};

const otherComapyDocs = [{title: 'Compulsory form.pdf', image: CompanyDocs}];

const companyActivities = {
  title: 'Dividend',
  amount: '₦35,000',
  status: 'Pending',
  date: 'Sep 09, 2021',
  image: CompanyDocs,
  className: '',
  statusColor: Spot,
};

export const relatedInterest = [
  {
    logo: Wiflix,
    name: 'Wiflix',
  },
  {
    logo: PayStack,
    name: 'Paystack',
  },
];

export const userRewards = [
  {
    user: 'Michael Potter',
    reward: 'CASH_TO_INVEST',
    status: 'PENDING',
    userType: 'REFERRAL',
  },
  {
    user: 'James Doe',
    reward: 'STOCKS',
    status: 'APPROVED',
    userType: 'REFERRAL',
  },
  {
    user: 'Jane Doe',
    reward: 'CASH_TO_INVEST',
    status: 'PENDING',
    userType: 'REFERRAL',
  },
];

export const investors = [
  {
    image: Avatar,
    name: 'Alexander Steve',
    investmentsCount: 4,
    amount: 250000,
    followBtn: 'Follow',
    onBtnClick: () => {},
    className: '',
    showFollowBtn: true,
    showInvestmentsCount: true,
    showTotalInvestmentAmount: true,
  },
  {
    image: Avatar,
    name: 'Peter Alex',
    investmentsCount: 4,
    amount: 250000,
    followBtn: 'Follow',
    onBtnClick: () => {},
    className: '',
    showFollowBtn: true,
    showInvestmentsCount: true,
    showTotalInvestmentAmount: true,
  },
  {
    image: Avatar,
    name: 'Kojo Atoh',
    investmentsCount: 2,
    amount: 50000,
    followBtn: 'Follow',
    onBtnClick: () => {},
    className: '',
    showFollowBtn: true,
    showInvestmentsCount: true,
    showTotalInvestmentAmount: true,
  },
  {
    image: Avatar,
    name: 'Motto Motto',
    investmentsCount: 1,
    amount: 50000,
    followBtn: 'Follow',
    onBtnClick: () => {},
    className: '',
    showFollowBtn: true,
    showInvestmentsCount: true,
    showTotalInvestmentAmount: true,
  },
  {
    image: Avatar,
    name: 'Mariam Dangote',
    investmentsCount: 2,
    amount: 500000,
    followBtn: 'Follow',
    onBtnClick: () => {},
    className: '',
    showFollowBtn: true,
    showInvestmentsCount: true,
    showTotalInvestmentAmount: true,
  },
];

const recomendedData = {
  recommendationData: [
    {recommendation: 'Good Buy', percentage: 88, color: '#4ECC4E'},
    {recommendation: 'Neutral', percentage: 10, color: '#F5A623'},
    {recommendation: 'Bad Buy', percentage: 2, color: '#8D6CFA'},
  ],
  title: 'Expert Recommendations',
  classname: '',
  description:
    'Get indept professional investment recommendations on startups from top experts',
  overallRecommendation: 'Good Buy',
  overallReaction: '👍',
};

const pitchDeckProps = {
  image: Pitch,
  shareIcon: Share,
  downloadIcon: Download,
  onShare: () => {},
  onDownload: () => {},
};

const pitchDecksArr = [
  {...pitchDeckProps},
  {...pitchDeckProps},
  {...pitchDeckProps},
  {...pitchDeckProps},
  {...pitchDeckProps},
];

export const companyOverviewData = [
  {
    name: 'Founded',
    value: '2018',
    type: 'text',
  },
  {
    name: 'Location',
    value: 'Nigeria',
    type: 'text',
  },
  {
    name: 'Industry',
    value: 'Fintech',
    type: 'text',
  },
  {
    name: 'Website',
    value: 'https://paystack.com',
    type: 'link',
  },
  {
    name: 'Social Proof',
    value: [
      {link: 'https://cb.com', icon: FB},
      {link: 'https://facebook.com', icon: FB},
      {link: 'https://linkedin.com', icon: LN},
      {link: 'https://twitter.com', icon: TW},
    ],
    type: 'image',
  },
];

export const companyReportData = [
  {
    icon: Thesis,
    title: 'Thesis',
    data: {
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae, ullamcorper ornare lectus venenatis, eget. Malesuada elementum, donec metus, amet sit sed faucibus feugiat. Risus, ac, aliquam sodales etiam consectetur vestibulum. Nibh natoque risus diam ac et suspendisse fermentum urna. Quam donec tortor, urna, feugiat semper.  Aliquet ac eget ac ipsum, ut ultricies consectetur placerat eget. Imperdiet consequat, mattis quis volutpat at leo euismod amet. Vitae nisl elit et habitant quam odio. A purus commodo habitant adipiscing. Metus, odio risus, eros, sed diam, id. Amet, habitasse quis nunc sed elementum tortor morbi vel. Bibendum nunc habitant proin at in.  Nibh gravida in eget suspendisse interdum lacus vel eu sodales. Facilisis id est massa ornare ',
      barData: {
        labels: ['15 - 24', '15 - 24', '15 - 24', '15 - 24', '54 & above'],
        datasets: [
          {
            label: 'Country Report Data',
            data: [100, 300, 600, 200, 100],
            backgroundColor: [
              '#0047CC',
              '#0047CC',
              '#0047CC',
              '#0047CC',
              '#0047CC',
            ],
          },
        ],
      },
      pieData: [
        {
          sector: 'Average male payroll',
          percentage: 88,
          amount: '₦ 25,000,120.00',
        },
        {
          sector: 'Average female payroll',
          percentage: 18,
          amount: '₦ 20,000,120.00',
        },
        {
          average: '40M',
        },
      ],
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae, ullamcorper ornare lectus venenatis, eget. Malesuada elementum, donec metus, amet sit sed faucibus feugiat. Risus, ac, aliquam sodales etiam consectetur vestibulum. Nibh natoque risus diam ac et suspendisse fermentum urna. Quam donec tortor, urna, feugiat semper.  Aliquet ac eget ac ipsum, ut ultricies consectetur placerat eget. Imperdiet consequat, mattis quis volutpat at leo euismod amet. Vitae nisl elit et habitant quam odio. A purus commodo habitant adipiscing. Metus, odio risus, eros, sed diam, id. Amet, habitasse quis nunc sed elementum tortor morbi vel. Bibendum nunc habitant proin at in.Nibh gravida in eget suspendisse interdum lacus vel eu sodales. Facilisis id est massa ornare ',
    },
  },
  {
    icon: CityCollapse,
    title: 'Competitive Landscape',
  },
  {
    icon: CompetitiveAdvantage,
    title: 'Competitive Advantage',
  },
  {
    icon: InvestmentRisk,
    title: 'Risks',
  },
];

export const fundsAllocation = [
  {sector: 'Marketing', percentage: 60},
  {sector: 'Hiring', percentage: 20},
  {sector: 'Product Management', percentage: 15},
  {sector: 'Others', percentage: 5},
];

const newsItem = {
  type: 'large',
  tags: ['Finance', 'Technology'],
  views: 3000,
  title: 'Business insentives from Minister of tech',
  source: 'CNN News',
  image: NewsLarge,
  time: new Date('Wed Nov 24 2021 16:30:03 GMT+1'),
  reactions: 125,
  thumbnails: [Thumbnail1, Thumbnail2, Thumbnail3],
  companies: ['Paystack', 'Flutterwave', 'Patricia'],
  topReactions: ['🎉', '👍', '🤔'],
  emojis,
};

const coInvestorsData = {
  className: '',
  title: 'Techstar',
  subtitle: 'Leading Tech accelerator',
  cardImage: Avatar,
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium and praesentium eius path eh de farnace al monis calishs eh france de studio alaimin',
  onActionClick: () => {},
  otherInvesments: [
    {
      img: Thumbnail1,
      onOtherInvestmentClick: () => {},
    },
    {
      img: Thumbnail2,
      onOtherInvestmentClick: () => {},
    },
    {
      img: Thumbnail3,
      onOtherInvestmentClick: () => {},
    },
    {
      img: Thumbnail1,
      onOtherInvestmentClick: () => {},
    },
  ],
};

const keyFact = {
  className: '',
  title: 'Market Size',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium',
};

const expertsRecommendations = {
  name: 'Roel Aufderehar',
  avatar: Avatar,
  followers: 200,
  onFollow: () => {},
  following: true,
  title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium and praesentium eiuLorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium and praesentium eiu',
};

const milestones = [
  {
    title: 'First phase',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum leo ac in egestas diam porttitor sectetur adipiscing elit. Condimentum leo ac in egestas diam. ',
  },
  {
    title: 'Second phase',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum leo ac in egestas diam porttitor sectetur adipiscing elit. Condimentum leo ac in egestas diam. ',
  },
  {
    title: 'Third phase',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum leo ac in egestas diam porttitor sectetur adipiscing elit. Condimentum leo ac in egestas diam. ',
  },
  {
    title: 'Fourth phase',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum leo ac in egestas diam porttitor sectetur adipiscing elit. Condimentum leo ac in egestas diam. ',
  },
];

export const topReactions = [
  {key: 1, img: cry},
  {key: 2, img: clap},
  {key: 3, img: fire},
];

const posts = [
  {
    isVerified: true,
    timeStamp: '2m',
    comments: 324,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi nibh malesuada dui, proin. Eu dolor aliquet in pretium, senectus pellentesque ac feugiat netus. Facilisi sit et odio neque arcu mi et eleifend...',
    avatar: Avatar,
    username: '@ralphdedwards',
    topReactions,
  },
  {
    isVerified: true,
    timeStamp: '2m',
    comments: 324,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi nibh malesuada dui, proin. Eu dolor aliquet in pretium, senectus pellentesque ac feugiat netus. Facilisi sit et odio neque arcu mi et eleifend...',
    avatar: Avatar,
    username: '@johnDoe',
    topReactions,
  },
  {
    isVerified: true,
    timeStamp: '2m',
    comments: 324,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi nibh malesuada dui, proin. Eu dolor aliquet in pretium, senectus pellentesque ac feugiat netus. Facilisi sit et odio neque arcu mi et eleifend...',
    avatar: Avatar,
    username: '@ralphdedwards',
    topReactions,
    postImage: PostImg,
  },
];

const founders = [
  {
    image: STAFF1,
    name: 'LindSay Walton',
    role: 'Front-end Developer',
    linkedinURL: LN,
    twitterURL: TW,
  },
  {
    image: STAFF2,
    name: 'Courtney Henry',
    role: 'Designer',
    linkedinURL: LN,
    twitterURL: TW,
  },
];

const restOfTeam = [
  {
    image: STAFF3,
    name: 'LindSay Walton',
    role: 'Front-end Developer',
    linkedinURL: LN,
    twitterURL: TW,
  },
  {
    image: STAFF4,
    name: 'LindSay Walton',
    role: 'Front-end Developer',
    linkedinURL: LN,
    twitterURL: TW,
  },
  {
    image: STAFF5,
    name: 'LindSay Walton',
    role: 'Front-end Developer',
    linkedinURL: LN,
    twitterURL: TW,
  },
];

export const homeChart = [
  {
    sector: 'Subscriptions',
    percentage: 10,
  },
  {
    sector: 'daba Analysis',
    percentage: 20,
  },
  {
    sector: 'Fees',
    percentage: 30,
  },
  {
    sector: 'Others',
    percentage: 40,
  },
];

export const visaTypeEnum = [
  {
    key: 'E_ONE_TRADER',
    name: 'E-1 Trader Visa',
  },
  {
    key: 'E_TWO_INVESTOR',
    name: 'E-2 Investor Visa',
  },
  {
    key: 'E_THREE_WORKER',
    name: 'E-3 Worker Visa',
  },
  {
    key: 'F_ONE_STUDENT',
    name: 'F-1 Student Visa',
  },
  {
    key: 'H_ONE_B_WORKER',
    name: 'H-1-B Worker Visa',
  },
  {
    key: 'L_ONE_WORKER',
    name: 'L-1 Worker',
  },
  {
    key: 'O_ONE_WORKER',
    name: 'O-1 Worker',
  },
  {
    key: 'T_N_ONE_WORKER',
    name: 'T-N-1 Worker',
  },
  {
    key: 'OTHER',
    name: 'Other',
  },
  {
    key: 'NO_VISA',
    name: 'No Visa',
  },
  {
    key: 'NONE',
    name: 'None',
  },
];

export const citizenStatusEnum = [
  {
    key: 'GREEN_CARD_HOLDER',
    name: 'Green Card Holder',
  },
  {
    key: 'US_VISA_HOLDER',
    name: 'US Visa Holder',
  },
  {
    key: 'US_CITIZEN',
    name: 'US Citizen',
  },
  {
    key: 'OTHER',
    name: 'Other',
  },
];

export const annualNetIncomeEnum = [
  {
    key: 'UNDER_5K',
    name: 'Below $5K',
  },
  {
    key: 'RANGE_5K_TO_25K',
    name: '$5K - $25k',
  },
  {
    key: 'RANGE_25K_TO_50K',
    name: '$25K - $50K',
  },
  {
    key: 'RANGE_50K_TO_100K',
    name: '$50K - $100K',
  },
  {
    key: 'RANGE_100K_TO_299K',
    name: '$100K - $299K',
  },
  {
    key: 'ABOVE_300K',
    name: 'Above $300K',
  },
  {
    key: 'NONE',
    name: 'None',
  },
];

export const taxFilingStatusEnum = [
  {
    key: 'SINGLE',
    name: 'Single',
  },
  {
    key: 'HEAD_OF_HOUSE_HOLD',
    name: 'Head of Household',
  },
  {
    key: 'MARRIED_FILING_SEPARATELY',
    name: 'Married (Filing Separately)',
  },
  {
    key: 'MARRIED_FILING_TOGETHER',
    name: 'Married (Filing Together)',
  },
  {
    key: 'WIDOWED',
    name: 'Widowed',
  },
  {
    key: 'NONE',
    name: 'None',
  },
];

export const employmentStatusEnum = [
  {
    key: 'EMPLOYED_FULL_TIME',
    name: 'Employed FullTime',
  },
  {
    key: 'EMPLOYED_PART_TIME',
    name: 'Employed PartTime',
  },
  {
    key: 'STUDENT',
    name: 'Student',
  },
  {
    key: 'RETIRED',
    name: 'Retired',
  },
  {
    key: 'FREE_LANCER',
    name: 'FreeLancer',
  },
  {
    key: 'UNEMPLOYED',
    name: 'Unemployed',
  },
  {
    key: 'NONE',
    name: 'None',
  },
];

export const estimatedNetWorthEnum = [
  {
    key: 'UNDER_5K',
    name: 'Below $5K',
  },
  {
    key: 'RANGE_5K_TO_25K',
    name: '$5K - $25k',
  },
  {
    key: 'RANGE_25K_TO_50K',
    name: '$25K - $50K',
  },
  {
    key: 'RANGE_50K_TO_100K',
    name: '$50K - $100K',
  },
  {
    key: 'RANGE_100K_TO_200K',
    name: '$100K - $200K',
  },
  {
    key: 'ABOVE_200K',
    name: 'Above $200K',
  },
  {
    key: 'ABOVE_MILLION',
    name: 'Above $1M',
  },
  {
    key: 'NONE',
    name: 'None',
  },
];

export const retirementSavingsEnum = [
  {
    key: 'FOUR_ONE_K',
    name: 'I save with 401K',
  },
  {
    key: 'IRA',
    name: 'I save with IRA',
  },
  {
    key: 'IRA_AND_401K',
    name: 'I save with both IRA AND 401K',
  },
  {
    key: 'SAVINGS_INVESTMENT_ACCOUNT',
    name: 'I have a savings investment account',
  },
  {
    key: 'NOT_SAVING',
    name: 'I am not saving',
  },
  {
    key: 'NONE',
    name: 'None',
  },
];

export const InvestingExperienceEnum = [
  {
    key: 'AMATEUR',
    name: 'I am an amateur',
  },
  {
    key: 'BEGINNER',
    name: 'I am a beginner',
  },
  {
    key: 'LICENSED',
    name: 'I am a licensed investor',
  },
  {
    key: 'EXPERIENCED',
    name: 'I am an experienced investor',
  },
  {
    key: 'NONE',
    name: 'None',
  },
];

export const investmentPreferenceEnum = [
  {
    key: 'ONLY_STABILITY',
    name: 'I prefer stability only',
  },
  {
    key: 'STABILITY_WITH_GROWTH',
    name: 'I prefer stability with growth',
  },
  {
    key: 'MIX_OF_STABILITY_AND_GROWTH',
    name: 'I prefer a mix of stability and growth',
  },
  {
    key: 'MOSTLY_GROWTH_WITH_STABILITY',
    name: 'I prefer more of growth with stability',
  },
  {
    key: 'PURE_GROWTH',
    name: 'I prefer fully growth',
  },
];

export const investmentHorizonEnum = [
  {
    key: 'LESS_THAN_ONE_YEAR',
    name: 'Less than 1year',
  },
  {
    key: 'RANGE_ONE_TO_FIVE_YEARS',
    name: 'Between 1 - 5years',
  },
  {
    key: 'RANGE_FIVE_TO_TEN_YEARS',
    name: 'Between 5 - 10years',
  },
  {
    key: 'RANGE_GREATER_THAN_TEN_YEAR',
    name: 'Greater than 10years',
  },
];

export const investmentAffiliationEnum = [
  {
    key: 'NONE',
    name: 'None',
  },
  {
    key: 'TEN_PERCENT_SHARE_HOLDER',
    name: '10% share holder of a public traded Company',
  },
  {
    key: 'PUBLIC_OR_POLITICAL_OFFICIAL',
    name: 'Public or Political official',
  },
  {
    key: 'BROKERAGE_FIRM',
    name: 'Work at another Broker Firm',
  },
  {
    key: 'VC_FUNDED',
    name: 'VC funded',
  },
  {
    key: 'ENTITIY_OR_TRUST',
    name: 'Entity or Trust',
  },
];

export const investmentGoalsEnum = [
  {
    key: 'betterBudgetting',
    name: 'Building better budgeting habits',
  },
  {
    key: 'stockRewards',
    name: 'Earn stocks rewards',
  },
  {
    key: 'emergencySavings',
    name: 'Save for the unexpected',
  },
  {
    key: 'retirementSavings',
    name: 'Save for Retirement',
  },
  {
    key: 'wealthBuilding',
    name: 'Invest and build wealth',
  },
  {
    key: 'education',
    name: 'Get financial advice and education',
  },
  {
    key: 'socialImpact',
    name: 'Social Impact',
  },
];

export const investmentStrategiesEnum = [
  {
    key: 'P1',
    name: 'P1',
  },
  {
    key: 'P2',
    name: 'P2',
  },
  {
    key: 'P3',
    name: 'P3',
  },
  {
    key: 'P4',
    name: 'P4',
  },
  {
    key: 'P5',
    name: 'P5',
  },
  {
    key: 'P6',
    name: 'P6',
  },
  {
    key: 'P7',
    name: 'P7',
  },
];

export const rewardSchemeType = [
  {
    key: 'REFERRAL',
    name: 'REFERRAL',
  },
  {
    key: 'TRANSACTIONAL',
    name: 'TRANSACTIONAL',
  },
];
export const userTypeEnum = [
  {
    key: 'REFERRER',
    name: 'REFERRER',
  },

  {
    key: 'USER',
    name: 'USER',
  },
];

export const rewardType = [
  {
    key: 'STOCKS',
    name: 'Stocks',
  },
  {
    key: 'CASH_TO_INVEST',
    name: 'Cash to invest',
  },
  {
    key: 'TRANSACTION_FEE_WAIVER',
    name: 'Transaction fee waiver',
  },
];

export const referralRewardType = [
  {
    key: 'STOCKS',
    name: 'Stocks',
  },
  {
    key: 'CASH_TO_INVEST',
    name: 'Cash to invest',
  },
];

export const operationTypeEnum = [
  {
    key: 'INVESTMENT',
    name: 'Investment',
  },
  {
    key: 'DEPOSIT',
    name: 'Deposit',
  },
];

export const rewardTriggerEnum = [
  {
    key: 'FIRST',
    name: 'First',
  },
  {
    key: 'ANY',
    name: 'Any',
  },
];

export const rewardStatus = [
  {
    key: 'APPROVED',
    name: 'Approve',
  },
  {
    key: 'PENDING',
    name: 'Pending',
  },
  {
    key: 'REJECTED',
    name: 'Reject',
  },
];

export const rewardAssetClass = [
  {
    key: 'STOCKS',
    name: 'Stocks',
  },
  {
    key: 'VENTURE',
    name: 'Venture',
  },
  {
    key: 'VENTURE_FUND',
    name: 'Venture Fund',
  },
  {
    key: 'IPO',
    name: 'IPO',
  },
];

export const announcementType = [
  {
    key: 'GENERAL',
    name: 'General',
  },
  {
    key: 'EVENT',
    name: 'Event',
  },
  {
    key: 'SUBSCRIPTION',
    name: 'Subscription',
  },
  {
    key: 'OPPORTUNITY_LISTING',
    name: 'Opportunities',
  },
  {
    key: 'NEW_REPORT',
    name: 'Reports',
  },
  {
    key: 'VIDEO',
    name: 'Video',
  },
  {
    key: 'AUDIO',
    name: 'Audio',
  },
  {
    key: 'SINGLE_REPORT',
    name: 'Single Report',
  },
  {
    key: 'SINGLE_OPPORTUNITY',
    name: 'Single Opportunity',
  },
  {
    key: 'SAVINGS',
    name: 'Savings',
  },
  {
    key: 'REWARD',
    name: 'Reward',
  },
];

export const documentCategory = [
  {
    key: 'GENERAL_ACCOUNT_STATEMENT',
    name: 'General Account Statement',
  },
  {
    key: 'ADVISORY_ACCOUNT_STATEMENT',
    name: 'Advisory Account Statement',
  },
  {
    key: 'SPENDING_ACCOUNT_STATEMENT',
    name: 'Spending Account Statement',
  },
  {
    key: 'TAX_DOCUMENTS',
    name: 'Tax Documents',
  },
  {
    key: 'INVESTMENT_CERTIFICATES',
    name: 'Investment Certificates',
  },
  {
    key: 'SUBSCRIPTION_DOCUMENTS',
    name: 'Subscription Documents',
  },
];

export const SubscriptionEnum = [
  {
    key: 'DABA_STARTER',
    name: 'Daba Starter Plan',
  },
  {
    key: 'DABA_PRO',
    name: 'Daba Pro Plan',
  },
  {
    key: 'NONE',
    name: 'Free Plan',
  },
];

export const stocksData = [
  {
    companyName: 'MTN',
    ticker: 'MTNN',
    price: '10,450',
    currency: 'FCFA',
    buyOrders: '20',
    sellOrders: '40',
    status: true,
    lastUpdated: '02/02/23 at 09:34 PM',
  },
  {
    companyName: 'MTN',
    ticker: 'MTNN',
    price: '10,450',
    currency: 'FCFA',
    buyOrders: '20',
    sellOrders: '40',
    status: false,
    lastUpdated: '02/02/23 at 09:34 PM',
  },
];

export const stocksListingData = [
  {
    name: 'King',
    username: 'ThatKingGuy',
    email: 'etiosaire',
    ticker: 'NDMA',
    orderType: 'BUY',
    status: 'PLACED',
    method: 'Wire_Manual',
    amount: '$3,346',
    shares: 754,
    date: '02/02/23',
    time: '3:45 PM',
  },
  {
    name: 'Ovie',
    username: 'Eng.',
    email: 'OG',
    ticker: 'NDMA',
    orderType: 'BUY',
    status: 'PLACED',
    method: 'Wire_Manual',
    amount: '$3,346',
    shares: 754,
    date: '02/02/23',
    time: '3:45 PM',
  },
];

export const currencySymbols = {
  'EUR': {value: 'EUR', symbol: '€', name: 'Euro'},
  'XOF': {value: 'XOF', symbol: 'CFA', name: 'CFA Franc'},
  'XAF': {value: 'XAF', symbol: 'FCFA', name: 'CFA Franc'},
  'CAD': {value: 'CAD', symbol: 'C$', name: 'Canadian Dollars'},
  'NGN': {value: 'NGN', symbol: '₦', name: 'Naira'},
  'USD': {value: 'USD', symbol: '$', name: 'US Dollars'},
  'GBP': {value: 'GBP', symbol: '£', name: 'Pounds'},
};

export const walletCurrencies = [
  {key: 'USD', symbol: '$', name: 'US Dollars'},
  {key: 'EUR', symbol: '€', name: 'Euros'},
  {key: 'GBP', symbol: '£', name: 'Pounds'},
  {key: 'NGN', symbol: '₦', name: 'Naira'},
  {key: 'XOF', symbol: 'FCFA', name: 'West African CFA Franc'},
  {key: 'XAF', symbol: 'CFA', name: 'Central African CFA Franc'},
];

export const stockOrderStatus = [
  {name: 'Pending', key: 'PENDING'},
  {name: 'Open', key: 'OPEN'},
  {name: 'Partially Filled', key: 'PARTIALLY_FILLED'},
  {name: 'Filled', key: 'FILLED'},
  {name: 'Cancelled', key: 'CANCELLED'},
  {name: 'Settled', key: 'SETTLED'},
  {name: 'Rejected', key: 'REJECTED'},
  {name: 'Error', key: 'ERROR'},
];

export const walletProviders = [
  {key: 'PLATFORM', label: 'Platform'},
  {key: 'DWOLLA', label: 'Dwolla'},
  {key: 'FLUTTERWAVE', label: 'Flutterwave'},
  {key: 'HUB2', label: 'Hub2'},
];

export const paymentProviders = [
  {key: 'PLATFORM', label: 'Platform'},
  {key: 'DWOLLA', label: 'Dwolla'},
  {key: 'FLUTTERWAVE', label: 'Flutterwave'},
  {key: 'DABA', label: 'Daba'},
  {key: 'HUB2', label: 'HUB2'},
];

export const transactionTypeEnum = [
  {key: 'DEPOSIT', label: 'Deposit'},
  {key: 'WITHDRAWAL', label: 'Withdrawal'},
  {key: 'DIVIDEND_PAYOUT', label: 'Dividend payout'},
  {key: 'DIVIDEND_REVERSAL', label: 'Dividend reversal'},
  {key: 'REWARDS_PAYIN', label: 'Rewards paying'},
  {key: 'LOCK_AMOUNT', label: 'Lock amount'},
  {key: 'UNLOCK_AMOUNT', label: 'Unlock amount'},
  {key: 'STOCK_SELL', label: 'Stock sell'},
  {key: 'STOCK_BUY', label: 'Stock buy'},
  {key: 'STOCK_SOLD', label: 'Stock sold'},
  {key: 'SAVING_WITHDRAWAL', label: 'Saving withdrawal'},
  {key: 'SAVING_DEPOSIT', label: 'Saving deposit'},
];

export const walletReportDirectionEnum = [
  {
    key: 'USER_BANK_ACCOUNT_TO_USER_WALLET',
    label: 'User Bank Account to User Wallet',
  },
  {
    key: 'USER_WALLET_TO_USER_BANK_ACCOUNT',
    label: 'User Wallet to User Bank Account',
  },
  {key: 'USER_WALLET_TO_STOCK_BUY', label: 'User Wallet to Stock Buy'},
  {key: 'USER_WALLET_TO_STOCK_SELL', label: 'User Wallet to Stock Sell'},
  {
    key: 'USER_WALLET_TO_VENTURE_INVESTMENT',
    label: 'User Wallet to Venture Investment',
  },
  {
    key: 'USER_WALLET_TO_VENTURE_FUND_INVESTMENT',
    label: 'User Wallet to Venture Fund Investment',
  },
  {
    key: 'USER_WALLET_TO_IPO_INVESTMENT',
    label: 'User Wallet to IPO Investment',
  },
  {key: 'DIVIDEND_PAYOUT', label: 'Dividend Payout'},
  {key: 'DIVIDEND_REVERSAL', label: 'Dividend Reversal'},
  {key: 'CURRENCY_SWAP', label: 'Currency Swap'},
  {key: 'PLATFORM_CREDIT_USER_BALANCE', label: 'Platform Credit User Balance'},
  {key: 'PLATFORM_DEBIT_USER_BALANCE', label: 'Platform Debit User Balance'},
  {
    key: 'USER_WALLET_TO_STARTUP_INVESTMENT',
    label: 'User Wallet to Startup Investment',
  },
  {key: 'REWARDS_PAYIN', label: 'Rewards Payin'},
  {key: 'DEPOSIT', label: 'Deposit'},
  {key: 'WITHDRAWAL', label: 'Withdrawal'},
  {key: 'LOCK_AMOUNT', label: 'Lock Amount'},
  {key: 'UNLOCK_AMOUNT', label: 'Unlock Amount'},
  {key: 'STOCK_SELL', label: 'Stock Sell'},
  {key: 'STOCK_BUY', label: 'Stock Buy'},
  {key: 'PLATFORM_FEE', label: 'Platform Fee'},
  {key: 'SAVINGS_WITHDRAWAL', label: 'Savings Withdrawal'},
  {key: 'SAVINGS_DEPOSIT', label: 'Savings Deposit'},
  {key: 'SAVINGS_DEPOSIT_REVERSAL', label: 'Savings Deposit Reversal'},
  {key: 'SAVINGS_WITHDRAWAL_REVERSAL', label: 'Savings Withdrawal Reversal'},
];

export const companyDocumentCategory = [
  {key: 'MONTHLY_REPORT', label: 'Monthly Report'},
  {key: 'QUARTERLY_REPORT', label: 'Quarterly Report'},
  {key: 'ANNUAL_REPORT', label: 'Annual Report'},
  {key: 'TAX_DOCUMENT', label: 'Tax Document'},
  {key: 'REGULATORY_FILING', label: 'Regulatory Filing'},
  {key: 'FINANCIALS', label: 'Financials'},
  {key: 'ANALYST_COMMENTARY', label: 'Analyst Commentary'},
  {key: 'MEETING_NOTES', label: 'Meeting Notes'},
  {key: 'OTHERS', label: 'Others'},
];

export const enabledPaymentMethod = [
  {key: 'WIRE_TRANSFER', value: 'Wire Transfer'},
  {key: 'BANK_TRANSFER', value: 'Bank Transfer'},
  {key: 'CRYPTO', value: 'Crypto'},
  {key: 'THIRD_PARTY_FLUTTERWAVE', value: 'Third Party Flutterwave'},
  {key: 'MOBILE_MONEY', value: 'Mobile Money'},
];

export const mobileMoneyOperators = [
  {key: 'ORANGEMONEY', value: 'Orange Money'},
  {key: 'FMM', value: 'FMM'},
  {key: 'EMONEY', value: 'E Money'},
  {key: 'FREEMONEY', value: 'Free Money'},
  {key: 'MTN', value: 'MTN'},
  {key: 'MOBICASH', value: 'MOBICASH'},
  {key: 'WAVE', value: 'WAVE'},
  {key: 'MOOV', value: 'MOOV'},
  {key: 'TOGOCELL', value: 'Togo Cell'},
  {key: 'ECOBANK', value: 'Eco Bank'},
];

export const senegalMoneyOperators = [
  {key: 'ORANGEMONEY', value: 'Orange Money'},
  {key: 'FREEMONEY', value: 'Free Money'},
  {key: 'WAVE', value: 'Wave'},
  {key: 'EMONEY', value: 'E Money'},
];

export const cotedvoreMoneyOperators = [
  {key: 'ORANGEMONEY', value: 'Orange Money'},
  {key: 'WAVE', value: 'WAVE'},
  {key: 'MOOV', value: 'MOOV'},
  {key: 'FREEMONEY', value: 'Free Money'},
  {key: 'MTN', value: 'MTN'},
  {key: 'EMONEY', value: 'E Money'},
  {key: 'MOBICASH', value: 'Mobi Cash'},
  {key: 'FMM', value: 'FMM'},
];

export const walletType = [
  {key: 'ALL', value: 'All'},
  {key: 'DEPOSIT', value: 'Deposit'},
  {key: 'WITHDRAWAL', value: 'Withdrawal'},
  {key: 'DIVIDEND_DEPOSIT', value: 'Dividend Deposit'},
  {key: 'DIVIDEND_REVERSAL', value: 'Dividend Reversal'},
  {key: 'REFERRAL_REWARDS_PAYIN', value: 'Referral Reward Payin'},
  {key: 'LOCK_AMOUNT', value: 'Lock Amount'},
  {key: 'UNLOCK_AMOUNT', value: 'Unlock Amount'},
  {key: 'STOCK_SELL', value: 'Stock Sell'},
];

export const mobileMoneySupportedCountries = [
  {key: 'SENEGAL', value: 'Senegal', countryCode: '+221'},
  {key: 'COTE_D_IVOIRE', value: "Cote D'Ivoire", countryCode: '+225'},
  {key: 'BENIN', value: 'Benin', countryCode: '+229'},
  {key: 'TOGO', value: 'Togo', countryCode: '+228'},
  {key: 'BURKINA_FASO', value: 'Burkina Faso', countryCode: '+226'},
  {key: 'MALI', value: 'Mali', countryCode: '+223'},
  {key: 'CAMEROON', value: 'Cameroon', countryCode: '+237'},
];

export const seededDocumentsCategories = [
  {
    key: 'PUBLICATIONS_MONTHLY_BULLETINS',
    label: 'Publications Monthly Bulletins',
  },
  {key: 'PUBLICATIONS_NOTICES', label: 'Publications Notices'},
  {
    key: 'PUBLICATIONS_OTHER_PUBLICATIONS',
    label: 'Publications Other Publications',
  },
  {
    key: 'PUBLICATIONS_OFFICIAL_NEWS_LETTERS',
    label: 'Publications Official Newsletters',
  },
  {key: 'DAILY_ACTIVITY_SUMMARY', label: 'Daily Activity Summary'},
  {key: 'WEEKLY_ACTIVITY_SUMMARY', label: 'Weekly Activity Summary'},
  {key: 'MONTHLY_REPORT', label: 'Monthly Report'},
  {key: 'QUARTERLY_REPORT', label: 'Quarterly Report'},
  {key: 'ANNUAL_REPORT', label: 'Annual Report'},
  {key: 'TAX_DOCUMENT', label: 'Tax Document'},
  {key: 'REGULATORY_FILING', label: 'Regulatory Filling'},
  {key: 'FINANCIALS', label: 'Financials'},
  {key: 'ANALYST_COMMENTARY', label: 'Analyst Commentary'},
  {key: 'MEETING_NOTES', label: 'Meeting Notes'},
  {key: 'GENERAL_COMPANY_REPORT', label: 'General Company Report'},
  {key: 'OTHERS', label: 'Others'},
  {key: 'BI_ANNUAL_REPORT', label: 'Bi Annual Report'},
];

export const kycStatusEnums = [
  {label: 'Not Started', value: 'NOT_STARTED'},
  {label: 'Pending', value: 'PENDING'},
  {label: 'Pending Verification', value: 'PENDING_VERIFICATION'},
  {label: 'Approved', value: 'APPROVED'},
  {label: 'Rejected', value: 'REJECTED'},
];

export const depositStatus = [
  {label: 'Pending', value: 'PENDING'},
  {label: 'Successful', value: 'SUCCESSFUL'},
  {label: 'Failed', value: 'FAILED'},
  {label: 'Cancelled', value: 'CANCELLED'},
];

export const profileCompletionEnum = [
  {label: 'Step One - Basics', value: 'ZERO_TO_TWENTY_FIVE'},
  {label: 'Step Two - Preferences', value: 'TWENTY_FIVE_TO_FIFTY'},
  {label: 'Step Three - Investments', value: 'FIFTY_TO_SEVENTY_FIVE'},
  {label: 'Step Four - Customization', value: 'SEVENTY_FIVE_TO_HUNDRED'},
];
